import React, { Component } from 'react';
import Api from '../../Components/Api';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import DateTimePicker from "../../Components/DatePicker.js";
import moment from 'moment';
import firebase from "firebase";
UIkit.use(Icons);

export default class ConsultAppointmentList extends Component {
  state = {
    list: [],
    listAll: [],
    group: 'self',
    dateStart: moment().startOf('day'),
    dateStop: moment().add(6, 'month'),
    loadingApi: false,
    filterCampaign: 'all'
  }

  memberById = {} ;

  consultById ={} ;

  campaignOption = [
    {label: "แคมเปญทั้งหมด", value: "all"},
  ];

  firebaseChatlistUpdate = (memberid, consultid) => {
    firebase.database().ref(`chatlist_m/${memberid}`).set(+new Date());
  }
  
  logVideoCallSend = async (consultId, memberId, campaignId) => {
    var _r = await Api.member("LogVideoCallAdd", {
      consultId,
      memberId,
      campaignId,
      chatBy: 'consult'
    }); 

    var result = await Api.consult("ChatMessageAdd", {
      consultId: consultId,
      memberId: memberId,
      campaignId: campaignId,
      typeMessage: "text",
      memberRead: 0,
      consultRead: 1,
      message: `มีการเรียกระบบวีดีโอคอล กดลิงค์ เพื่อเข้าสู่หน้าวีดีโอคอล
      https://con.defence-innovation.com/${memberId}/${consultId}?openExternalBrowser=1`,
      chatby: 'c',
    });
 

    this.firebaseChatlistUpdate(memberId, consultId);
  };

  listGet = async  () => {
    this.setState({loadingApi: true});

    var {list, consult, member} = await Api.consult("AppointmentList2", {
      dateStart: moment(this.state.dateStart).format('YYYY-MM-DD 00:00:00'),
      dateStop: moment(this.state.dateStop).format('YYYY-MM-DD HH:mm:ss'),
      group: this.state.group
    });



    
    if(!list) list = [];
    if(!member) member = [];
    if(!consult) consult = [];

    member.forEach((data) => {
      this.memberById[data.id] = data;
    });

    consult.forEach((data) => {
      this.consultById[data.id] = data;
    });

    var _listByKey = {};

    list.forEach((list)=>{
      if(_listByKey[list.campaignid] == null) {
        _listByKey[list.campaignid] = list.campaignname;
      }
    });

    this.campaignOption = [
      {label: "แคมเปญทั้งหมด", value: "all"},
    ];

    for(var k in _listByKey) {
      this.campaignOption.push({
        label: _listByKey[k],
        value: k
      })
    };


    this.setState({
      listAll: list,
      loadingApi: false
    }, ()=>{
      this.listFilterRender();
    });
  }

  listFilterRender = () => {

    var _list = [];

    this.state.listAll.forEach((data)=>{
      
      if(this.state.filterCampaign !== 'all' && this.state.filterCampaign != data.campaignid) {
        return null;
      } else {
        _list.push(data);
      }
    });

    this.setState({list: _list});
    
  }

  vdocall_suggest_update = async (id, value) => {

    var _r = await Api.consult("AppointmentVdocallSuggestUpdate", {
      id,
      value
    });

  }

  componentDidMount() {
    this.listGet();
  }

  render() {
    return <div className="uk-padding">
      <div className="uk-card uk-card-default uk-padding-small">
        <div className="d-card-header">ตารางนัดและการให้คำแนะนำโดยพยาบาลหรือเภสัชกร</div>
        <div uk-grid="" className="uk-flex-middle">
          <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker id="dateStart" date={this.state.dateStart} callback={(value)=>this.setState({dateStart: value})}/>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
              <DateTimePicker id="dateStop" date={this.state.dateStop} callback={(value)=>this.setState({dateStop: value})} />
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <label className="uk-margin-small-right">
              <input type="radio" className="uk-radio" name="group_select" checked={this.state.group === 'self'} onChange={()=>this.setState({group: 'self'})} /> เฉพาะตนเอง
            </label>
            <label>
              <input type="radio" className="uk-radio" name="group_select" checked={this.state.group === 'all'} onChange={()=>this.setState({group: 'all'})} /> รวมทุกคน
            </label>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <button className="uk-button uk-button-primary" onClick={this.listGet}>ค้นหา</button>
          </div>
          <div className="uk-width-1-5@m uk-width-1-1">
            <select className="uk-select" defaultValue={this.state.filterCampaign} onChange={e=>this.setState({filterCampaign: e.target.value}, ()=>{
              this.listFilterRender();
            })}>
              {this.campaignOption.map((option)=>{
                return <option value={option.value}>{option.label}</option>
              })}
            </select>
          </div>
        </div>

        <hr />
        <div className="uk-overflow-auto">
        <table className="uk-table uk-table-small uk-table-divider uk-table-striped">
            <thead>
              <tr>
                <th>ที่</th>
                <th>วันที่</th>
                <th>เวลา</th>
                <th>ชื่อคนไข้</th>
                <th>นัดหมายเพื่อ</th>
                <th>ผู้นัดหมาย</th>
                <th>แคมเปญ</th>
                <th>ช่องทาง</th>
                <th>หมายเหตุ</th>
                <th>การให้คำแนะนำ</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.loadingApi === true ?
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center"><span uk-spinner=""></span></div>
                  </td>
                </tr>
                :
                this.state.list.length === 0 ? 
                <tr>
                  <td colSpan="100%">
                    <div className="uk-padding-small uk-text-center">ไม่พบข้อมูล</div>
                  </td>
                </tr>
                :

                this.state.list.map((data, key)=>{ 
                  var _member = this.memberById[data.memberid];

                  if(_member) {
                    _member = `${_member.nameprefix} ${_member.name} ${_member.lastname}`;
                  } else {
                    _member = '-';
                  }

                  var _consult = this.consultById[data.consultid];
                  if(_consult) {
                    _consult = `${_consult.nameprefix} ${_consult.name} ${_consult.lastname} (${_consult.username}) ${_consult.typeconsult ? `<ประเภท : ${_consult.typeconsult}>` : ''}`;
                  } else {
                    _consult = '-';
                  }
  
                  return <tr key={`row${data.id}`} data-id={data.id}>
                    <td>{key+1}</td>
                    <td>
                      {moment(data.dateadd).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {moment(data.dateadd).format('HH:mm น.')}
                    </td>
                    <td>{_member}</td>
                    <td>
                      <a
                        href={`https://con.defence-innovation.com/${data.memberid}/${data.consultid}?openExternalBrowser=1`}
                        className="uk-margin-right"
                        target="_blank"
                        onClick={()=>{
                          this.logVideoCallSend(data.consultid, data.memberid, data.campaignid)
                        }}
                      >
                        กดวีดีโอคอล
                      </a>
                      <a href={`/consult/chat?messageSelectIndex=ca${data.campaignid}_co${data.consultid}_m${data.memberid}`}>{data.name}</a>
                      
                    </td>
                    <td>{_consult}</td>
                    <td>{data.campaignname}</td>
                    <td>{data.type}</td>
                    <td>{data.note}</td>
                    <td>
                      <label className='uk-text-normal'> 
                      <input type="checkbox" className='uk-checkbox' checked={data.vdocall_suggest == '1'} onChange={(e)=>{
                        data.vdocall_suggest =(e.target.checked === true ? "1" : "0");
                        this.vdocall_suggest_update(data.chatappointmentid, data.vdocall_suggest);
                        this.forceUpdate()
                      }} /> ให้คำแนะนำผู้ป่วยทางวีดีโอคอลแล้ว
                      </label>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
          </div>
      </div>
    </div>
  }
};
